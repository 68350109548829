import React, { useState, useEffect } from 'react';
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Route, Routes, useLocation, Link } from 'react-router-dom';
import ProductInfo from './components/ProductInfo/ProductInfo';
import Homepage from './components/Homepage/Homepage';
import Cart from './components/Cart/Cart';
import Navbar from './components/Navbar/Navbar';
import CategoryCards from './components/CategoryCards/CategoryCards';
import Login from './components/Login/Login';
import Footer from './components/Footer/Footer';
import Register from './components/Register/Register';
import { Checkout } from './components/Checkout/Checkout';
import { PaymentSuccess } from './components/PaymentSuccess/PaymentSuccess';
import { MyOrders } from './components/MyOrders/MyOrders';
import AboutUs from './components/AboutUs/AboutUs';
import ChildCategoryCards from './components/ChildCategoryCards/ChildCategoryCards';
import { Wishlist } from './components/Wishlist/Wishlist';
import { BannerProducts } from './components/BannerProducts/BannerProducts';
import SitemapComponent from './components/Sitemap/Sitemap';
import { PaymentCancel } from './components/PaymentCancel/PaymentCancel';
import MyOrdersTrack from './components/OrderTrackDetails/OrderTrackDetails';
import { useProductContext } from './/Contexts/ProductContext';
import { useCartContext } from './/Contexts/CartContext';
import { useCompanyContext } from './/Contexts/CompanyContext';
//import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import Password from './components/ForgotPassword/ForgotPassword';
import Button from '@mui/material/Button';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Address from './components/Address/Address';
import Search from './components/Search/Search';
import MyOrderNew from './components/MyOrders/MyOrderNew';
import RedirectPayment from './components/RedirectPayment/RedirectPayment';
import MyOrdersForm from './components/MyOrdersForm/MyOrdersForm';
import RegisterNew from './components/Register/RegisterNew';
import LoginNew from './components/Login/LoginNew';

// ErrorBoundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
    this.setState({ hasError: true });
  }

  reloadPage = () => {
    window.location.href = '/';
    // window.location.reload(true);
  };

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ minHeight: '800px', textAlign: 'center', marginTop: '200px' }}>
          <SentimentVeryDissatisfiedIcon sx={{ fontSize: 150, color: '#b8b2b2' }}></SentimentVeryDissatisfiedIcon>
          <br />
          <br />
          <h1>404 Page Not Found</h1>
          <p>Oops! It looks like the page you are looking for does not exist!</p>
          <br />
          <Button variant='contained' onClick={this.reloadPage} >Go to Shop</Button>
        </div>
      );
    }
    return this.props.children;
  }
}

function App() {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const currentUser = JSON.parse(localStorage.getItem('user'));


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  const hideNavbar = location.pathname === '/login' || location.pathname === '/logins' || location.pathname === '/registers' || location.pathname === '/register' || location.pathname === '/forgot_password';
  const hideFooter = location.pathname === '/login' || location.pathname === '/register';
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get('product');


  return (
    <HelmetProvider>
      <ErrorBoundary> {/* Wrap the entire App with ErrorBoundary */}
        {!hideNavbar && <Navbar setSearchTerm={setSearchTerm} />}
        <Routes>
          <Route path="/" element={<Homepage searchTerm={searchTerm} />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/order_details" element={<MyOrdersTrack />} />
          <Route path="/logins" element={<Login />} />
          <Route path="/login" element={<LoginNew />} />
          <Route path="/registers" element={<Register />} />
          <Route path="/register" element={<RegisterNew />} />
          <Route path="/forgot_password" element={<Password />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/address" element={<Address />} />
          <Route path="/search" element={<Search searchTerm={searchTerm} />} />
          <Route path="/success" element={<PaymentSuccess />} />
          <Route path="/cancel" element={<PaymentCancel />} />
          {/* <Route path="/myorders" element={<MyOrders />} /> */}
          <Route path="/myorders" element={<MyOrderNew />} />
          <Route path="/myorders/:orderid" element={<MyOrdersForm />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/bannerproducts" element={<BannerProducts />} />
          <Route path="/sitemap.xml" element={<SitemapComponent />} />
          <Route path="/redirect/url" element={<RedirectPayment />} />
          <Route path="/:1" element={productId ? <ProductInfo searchTerm={searchTerm} /> : <CategoryCards searchTerm={searchTerm} />} />
          <Route path="/:1/:2" element={productId ? <ProductInfo searchTerm={searchTerm} /> : <ChildCategoryCards searchTerm={searchTerm} />} />
          <Route path="/:1/:2/:3" element={productId ? <ProductInfo searchTerm={searchTerm} /> : <ChildCategoryCards searchTerm={searchTerm} />} />
          <Route path="/:1/:2/:3/:4" element={productId ? <ProductInfo searchTerm={searchTerm} /> : <ChildCategoryCards searchTerm={searchTerm} />} />
          <Route path="/:1/:2/:3/:4/:5" element={productId ? <ProductInfo searchTerm={searchTerm} /> : <ChildCategoryCards searchTerm={searchTerm} />} />
          <Route path="/:1/:2/:3/:4/:5/:6" element={productId ? <ProductInfo searchTerm={searchTerm} /> : <ChildCategoryCards searchTerm={searchTerm} />} />
          <Route path="/:1/:2/:3/:4/:5/:6:/7" element={productId ? <ProductInfo searchTerm={searchTerm} /> : <ChildCategoryCards searchTerm={searchTerm} />} />
          <Route path="/:1/:2/:3/:4/:5/:6:/7/:8" element={productId ? <ProductInfo searchTerm={searchTerm} /> : <ChildCategoryCards searchTerm={searchTerm} />} />
          {/* <Route path="page-not-found" element={<NotFoundPage />} />  */}
        </Routes>
        <br />
        {/* {!hideFooter && <Footer />} */}
      </ErrorBoundary>
    </HelmetProvider>
  );
}

export default App;

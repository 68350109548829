import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProductContext } from '../../Contexts/ProductContext';
import { useCompanyContext } from '../../Contexts/CompanyContext';
import CategoryCarousel from '../CategoryCarousel/CategoryCarousel';
import TrendingItemsCarousel from '../Trending/Trending';
import { Carousel2 } from '../Carousel2/Carousel2';
import { Banner } from '../Banner/Banner';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CategoryCarouselMobile from '../CategoryCarouselMobile/CategoryCarouselMobile';
import HomePageProductSlider from '../Carousel/Carousel';
import { HomePageExtend } from '../../Apps/HomePageExtend';
import './Homepage.css';
import MobileFooter from '../MobileFooter/MobileFooter';
import { Helmet } from 'react-helmet';
import NoProduct from './noproduct.png';
import Swal from 'sweetalert2';
import banner1 from './banner.jpg';
import banner2 from './banner_1.jpg';
import CategoryWisePorducts from '../CategoryWisePorducts/CategoryWisePorducts';
import FilteredCategoryProduct from '../FilteredCategoryProduct/FilteredCategoryProduct';
import Footer from '../Footer/Footer';

const Homepage = ({ searchTerm }) => {
  const navigate = useNavigate();
  const { products, loading } = useProductContext();
  const { companyDetails } = useCompanyContext();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  const placeholderArray = ['Search "sugar"', 'Search "flour"', 'Search "bread"', 'Search "milk"'];
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholderArray[0]);
  const companyName = localStorage.getItem('companyName');
  const companyImage = localStorage.getItem('companyImage');
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    const hasReloaded = sessionStorage.getItem('hasReloaded');

    if (!hasReloaded) {
      sessionStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }

  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 553);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    if (!loading && products.length > 0) {
      setDataLoaded(true);
    }
  }, [loading, products]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPlaceholderIndex(prevIndex => (prevIndex + 1) % placeholderArray.length);
    }, 1500); // 2000ms = 2 seconds

    setCurrentPlaceholder(placeholderArray[placeholderIndex]);

    return () => clearInterval(intervalId);
  }, [placeholderIndex, placeholderArray]);


  var abc;
  if (products && products.length > 0) {
    const bannerObjValDot = products[0];
    abc = bannerObjValDot['bannerObjVal'];
  }

  const decodeBase64Image = (base64) => {
    const decodedImage = new Image();
    decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
    return decodedImage.src;
  };

  return (
    <div className="homepage-container" style={{ minHeight: '600px', position: 'relative', paddingBottom: 0 }}>
      {!dataLoaded && (
        <div className="overlay">
          {/* <div className='loader' style={{ position: 'absolute', top: '30%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999' }}> */}

          <Box sx={{ display: 'flex' }}>
            <CircularProgress size={60} />
          </Box>
          {/* </div> */}
        </div>
      )
      }
      {/* <br /> */}
      {dataLoaded && (
        //   {products.length === 0 ? (
        //     <div className="no-products">
        //       <img src={NoProduct} alt='No Product' />
        //     </div>
        <>
          <div className='banner1'>
            {companyDetails && companyDetails.app_banner ?
              <img
                src={decodeBase64Image(companyDetails.app_banner)}
                alt="banner"
                loading="lazy"
                className="img-fluid circular-img"
                style={{ borderRadius: '30px' }}
              /> :
              <img
                src={banner1}
                alt="banner"
                loading="lazy"
                className="img-fluid circular-img"
                style={{ borderRadius: '30px' }}
              />
            }
          </div>
          {/* {isMobileView ? (
            <CategoryCarouselMobile searchTerm={searchTerm} />
          ) : (
            <CategoryCarousel searchTerm={searchTerm} />
          )} */}
          <br />
          <br />
          <Carousel2 searchTerm={searchTerm} activeCategory={selectedCategory}
            onCategorySelect={setSelectedCategory} />

          <br />

          <FilteredCategoryProduct selectedCategory={selectedCategory} />
          <TrendingItemsCarousel />
          <div className='banner1'>
            {companyDetails && companyDetails.app_banner_2 ?
              <img
                src={decodeBase64Image(companyDetails.app_banner_2)}
                alt="banner"
                loading="lazy"
                className="img-fluid circular-img"
                style={{ borderRadius: '30px' }}
              /> :
              <img
                src={banner2}
                alt="banner"
                loading="lazy"
                className="img-fluid circular-img"
                style={{ borderRadius: '30px' }}
              />
            }
          </div>
          <CategoryWisePorducts />
          <br />
          <Footer />
        </>
      )
      }
      {
        isMobileView && (
          <MobileFooter />
        )
      }

    </div >
  );
}

export default Homepage;

import React, { createContext, useContext, useState, useEffect } from 'react';

const CartContext = createContext();

export const useCartContext = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  //cartItems state from local storage
  const [cartItems, setCartItems] = useState(() => {
    const storedCartItems = localStorage.getItem('cartItems');
    let parsedCartItems = [];
    
    try {
      if (storedCartItems) {
        parsedCartItems = JSON.parse(storedCartItems);
      }
    } catch (error) {
      console.error('Error parsing cart items:', error);
      // Handle the error here, such as resetting local storage or displaying a message to the user
      // For example, you could reset local storage to prevent further issues:
      localStorage.removeItem('cartItems');
    }
  
    return parsedCartItems;
  });

  const addToCart = (product) => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    
    const existingProductIndex = storedCartItems.findIndex(item => item.id === product.id);
    if (existingProductIndex !== -1) {
      const updatedCartItems = [...storedCartItems];
      updatedCartItems[existingProductIndex].quantity += 1;
      setCartItems(updatedCartItems);
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    } else {
      const updatedCartItems = [...storedCartItems, { ...product, quantity: 1 }];
      setCartItems(updatedCartItems);
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    }
  };

  const removeFromCart = (productId) => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const updatedCartItems = storedCartItems.filter(item => item.id !== productId);
    setCartItems(updatedCartItems);
    localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
  };

  const increaseQuantity = (productId) => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const existingProductIndex = storedCartItems.findIndex(item => item.id === productId);
    if (existingProductIndex !== -1) {
      const updatedCartItems = [...storedCartItems];
      updatedCartItems[existingProductIndex].quantity += 1;
      setCartItems(updatedCartItems);
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    }
  };

  const decreaseQuantity = (productId) => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const existingProductIndex = storedCartItems.findIndex(item => item.id === productId);
    if (existingProductIndex !== -1) {
      const updatedCartItems = [...storedCartItems];
      if (updatedCartItems[existingProductIndex].quantity > 1) {
        updatedCartItems[existingProductIndex].quantity -= 1;
        setCartItems(updatedCartItems);
        localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
      }
    }
  };

  const clearCart = () => {
    setCartItems([]);
    localStorage.removeItem('cartItems');
  };

  const addFilteredProductsToCart = (filteredProducts) => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const updatedCartItems = [...storedCartItems];

    // console.log("cart context filtered products ================ ",filteredProducts);

    // Loop through filteredProducts and add them to the cart
    filteredProducts.forEach(product => {
      const existingProductIndex = updatedCartItems.findIndex(item => item.id === product.id);
      if (existingProductIndex !== -1) {
        // If product exists in cart, increase quantity
        // updatedCartItems[existingProductIndex].quantity += 1;
      } else {
        // If product does not exist in cart, add it with quantity 1
        updatedCartItems.push({ ...product, quantity: product.quantity });
      }
    });

    setCartItems(updatedCartItems);
    localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
  };


  return (
    <CartContext.Provider value={{ cartItems, addToCart,addFilteredProductsToCart, removeFromCart, clearCart, increaseQuantity, decreaseQuantity }}>
      {children}
    </CartContext.Provider>
  );
};

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCompanyContext } from '../../Contexts/CompanyContext';
import './MyOrderNew.css';
import { FaCheck, FaEllipsisV } from "react-icons/fa";
import dot from './dot.png';
import noOrdersImage from './emptymyorders.png';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { format, parseISO } from 'date-fns';
import MobileFooter from '../MobileFooter/MobileFooter';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const MyOrderNew = () => {
    const navigate = useNavigate();
    const { companyDetails } = useCompanyContext();
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
    const loggedinUser = localStorage.getItem('user');
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const endpoint = '/myorders';
    const url = `${apiUrl}${endpoint}`;

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                setLoading(true);
                if (loggedinUser) {
                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ username: loggedinUser })
                    });
                    const data = await response.json();

                    const updatedOrders = data.result.map(order => ({
                        ...order,
                        products: order.products.filter(product => product.type !== 'service'),
                        total: order.products.reduce((acc, product) => acc + (product.price_unit * product.quantity), 0),  // Calculate total for each order
                        shippingCharges: order.products.filter(p => p.type === 'service').reduce((acc, product) => acc + (product.price_unit * product.quantity), 0)
                    }));

                    setOrders(updatedOrders);

                }
            } catch (error) {
                console.error('Error fetching orders:', error);
            } finally {
                setLoading(false); // Stop loading
            }
        };

        fetchOrders();
    }, [loggedinUser]);

    const handleReorder = async (order) => {
        try {
            const userObj = JSON.parse(loggedinUser);
            const userId = userObj.user_id;
            const response = await fetch(`${apiUrl}/add_to_cart`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    user_id: userId,
                    cartItems: order.products
                })
            });
            const result = await response.json();

            if (result.success) {
                alert('Products added to cart successfully!');
            } else {
                alert(result.message);
            }
        } catch (error) {
            console.error('Error adding products to cart:', error);
        }
    };


    if (!loggedinUser) {
        return (
            <div className="my_order" style={{ minHeight: '1000px', textAlign: 'center', background: '#e7f2f7' }}>
                <div className="container">
                    <h5 style={{ textAlign: 'center', fontWeight: '300', paddingTop: '300px' }}>Please Login To View Your Orders</h5>
                    {/* <p className="subtitle">Please log in to see your orders.</p> */}
                    <br />
                    <Link to='/login'><Button size='small' variant='contained' color='primary' style={{ backgroundColor: '#375E97' }}>Login</Button></Link>
                </div>
                {isMobileView && (
                    <MobileFooter />
                )}
            </div>
        );
    }

    if (loading) {
        return (
            <div className='loader' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999' }}>
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress size={60} />
                </Box>
            </div>
        );
    }

    if (orders.length === 0) {
        return (
            <div className="my_order no-orders" style={{ minHeight: '1000px', textAlign: 'center', background: '#e7f2f7' }}>
                <h5 style={{ textAlign: 'center', fontWeight: '300', paddingTop: '300px' }}>You haven't placed any orders yet!<br /> Start shopping and make your first purchase today. Happy shopping!</h5>
                {/* <img src={noOrdersImage} alt="No Orders" style={{ marginTop: '200px' }} /> */}
                <br />
                <Link to='/'><Button size='small' variant='contained' color='primary'>Continue Shopping</Button></Link>
                {isMobileView && (
                    <MobileFooter />
                )}
            </div>
        );
    }

    const truncateNameForURL = (name, maxLength) => {
        const words = name.split(' ');
        if (words.length <= maxLength) {
            return words.join('-'); // If the number of words is less than or equal to maxLength, use all words
        }
        return words.slice(0, maxLength).join('-'); // Otherwise, use only the first maxLength words
    };

    return (
        <div className="myorder-wrap">
            <div className="container">
                <h2 style={{ marginBottom: '20px ' }}>My Orders</h2>
                {orders.map((order, index) => (
                    <div key={index} className="myorder-coent">
                        <Link to={`/myorders/${order.order_id}`}>
                            <div className="myorder-box myorder-flex or-border">
                                <div className="myorder-flex myorder-header" style={{ gap: 10 }}>
                                    <div style={{ display: 'flex', gap: 10 }} className='myorder-orderid'>
                                        <div className="order-check">
                                            <FaCheck style={{ fontSize: '22px' }} />
                                        </div>
                                        <div>
                                            <div className="order-loc"> Order #{order.order_name}</div>
                                            <div>
                                                <span>₹{order.total}</span>
                                                <span>
                                                    <img src={dot} alt="" />
                                                </span>
                                                <span>{format(parseISO(order.order_date), 'dd MMMM yyyy')}</span>
                                                {/* <span>17 Oct,</span> <span> 4:47 pm</span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='delivery-type'>
                                        <div className="order-loc">Delivery Type</div>
                                        <div>
                                            {order.order_type === 'dod' ?
                                                <span>Delivery on door</span> :
                                                <span>Takeaway</span>
                                            }
                                        </div>
                                    </div>
                                    <div className='delivery-address'>
                                        <div className="order-loc">Address</div>
                                        <div>
                                            {order.order_type === 'dod' ?
                                                <span>{order.delivery_address.street},{order.delivery_address.city},{order.delivery_address.state},{order.delivery_address.zip},{order.delivery_address.country}</span> :
                                                <span>{companyDetails?.company_address?.street},{companyDetails?.company_address?.city},{companyDetails?.company_address?.zip}, {companyDetails?.company_address?.country}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <FaEllipsisV />
                                </div>
                            </div>
                        </Link>
                        <div className="myorder-box or-border myorder-product">
                            {order.products.slice(0, 3).map((product, idx) => (
                                <div key={idx} id={`cartpro-${product.product_id}`} className="moorder-pro">
                                    <Link
                                        to={`/${product.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(product.product_name, 4))}-${encodeURIComponent(product.product_template_id)}`}
                                    >
                                        <img src={`data:image/jpeg;base64,${atob(product.image_base64)}`} alt="Product" />
                                    </Link>
                                </div>
                            ))}

                            {order.products.length > 3 && (
                                <div className="moorder-pro">
                                    <Link to={`/myorders/${order.order_id}`}>
                                        <span className="myorder-more">+{order.products.length - 3}</span>
                                    </Link>
                                </div>
                            )}
                        </div>

                        <div
                            className="myorder-box myorder-flex"
                            style={{
                                justifyContent: "flex-start",
                                padding: 0,
                                textAlign: "left",
                                display: 'flex'
                                // textAlign: "center"
                            }}
                        >
                            {/* <div className="myorderbtn orr-border">
                                <div className="btn myorder-btn" onClick={() => handleReorder(order)}>Reorder</div>
                            </div> */}
                            <div className="myorderbtn orl-border">
                                <div className="myorder-btn" style={{ marginTop: '10px' }}>Order Status: <span style={{
                                    color: order.status === 'sent'
                                        ? 'orange'
                                        : order.status === 'sale'
                                            ? 'green'
                                            : order.status === 'cancel'
                                                ? 'red'
                                                : 'black'
                                }}>
                                    {order.status === 'sent'
                                        ? 'Order Placed'
                                        : order.status === 'sale'
                                            ? 'Confirmed'
                                            : order.status === 'cancel'
                                                ? 'Rejected'
                                                : order.status}
                                </span></div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {isMobileView && (
                <MobileFooter />
            )}
        </div>

    )
}

export default MyOrderNew
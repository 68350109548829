import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { InputAdornment, IconButton } from '@mui/material';
// import { TextField, Button, IconButton, InputAdornment } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Swal from 'sweetalert2';
import image from './eShop-LOGO-BlackE.png';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { FacebookLogin } from '@greatsumini/react-facebook-login';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton } from 'react-social-login-buttons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './Register.css';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useCompanyContext } from '../../Contexts/CompanyContext';
import { FcGoogle } from "react-icons/fc";
import { FaFacebook, FaGoogle, FaEye, FaEyeSlash } from "react-icons/fa";
import NoLogo from './No_logo.png';

const defaultTheme = createTheme();

export default function SignUp() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const endpoint = '/register';
  const registerUrl = `${apiUrl}${endpoint}`;
  const endpointOtp = '/verify';
  const validateOtpurl = '/validate/otp';
  const endpointOtpUrl = `${apiUrl}${endpointOtp}`;
  const endPointvalidateOtp = `${apiUrl}${validateOtpurl}`
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isOtpValidated, setIsOtpValidated] = useState();
  const [setOtp, setsetOtp] = useState(0);
  const [message, setMessage] = useState('');
  const [timer, setTimer] = useState(60); // Initial timer value in seconds
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const [isSendClicked, setIsSendClicked] = useState(false);
  const [resendTriggered, setResendTriggered] = useState(false);
  const [isValidDomain, setIsValidDomain] = useState(true);
  const [isValidOTPButton, setIsValidOTPButton] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    passcode: '',
    password: '',
    repassword: '',
    phone: '',
  });

  const navigate = useNavigate();
  const location = useLocation();
  const companyID = localStorage.getItem('companyID');
  const companyName = localStorage.getItem('companyName');
  const { companyDetails } = useCompanyContext();
  const logoSrc = companyDetails ? `data:image/png;base64,${atob(companyDetails.company_image)}` : '';
  const dynamicURL = companyID ? `/home/${companyID}` : '/';
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const statelocation = location.state
  console.log("statelocation in register=========", statelocation);



  const decrementTimer = () => {
    setTimer((prevTimer) => {
      const minutes = Math.floor(prevTimer / 60);
      const seconds = prevTimer % 60;
      if (prevTimer <= 0) {
        return 0;
      }
      return (minutes * 60) + seconds - 1;
    });
  };

  useEffect(() => {
    if (isOtpVerified || resendTriggered) {

      const timerId = setInterval(decrementTimer, 800);
      return () => {
        clearInterval(timerId);
      };
    }
  }, [isOtpVerified, resendTriggered]);

  useEffect(() => {

    if (timer === 0) {
      setIsTimerExpired(true);
    }
  }, [timer]);
  const handleChangee = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const isValid = emailRegex.test(e.target.value);

    setIsValidDomain(isValid);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

  };
  const handleChangeOTP = (e) => {
    const { name, value } = e.target;
    const newValue = value.slice(0, 4);
    setFormData({ ...formData, [name]: newValue });
  };

  formData.companyName = companyName;

  const handlePhoneChange = (e) => {
    const value = e.target.value;

    // Allow only digits, and restrict input to 10 characters
    if (/^\d{0,10}$/.test(value)) {
      setFormData({ ...formData, phone: value });
    }
  };

  // email verification
  const verifyOtp = async (e) => {

    e.preventDefault();
    setIsVerifying(true);
    try {
      const response = await fetch(endpointOtpUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const responseData = await response.json();
        //const resultData = JSON.parse(responseData.result);

        const success = responseData.result.success;
        if (responseData.result.success === 'YES') {

          const otp = responseData.result.emailOtp
          setsetOtp(otp)
          setIsSendClicked(true);
          setIsOtpVerified(true);
          setMessage(responseData.message);

          Swal.fire({
            icon: 'info',
            title: 'Success',
            text: responseData.result.message,
          });
        }
        if (responseData.result.success === 'NO') {
          localStorage.removeItem('user');
          // Handle if email already exist
          Swal.fire({
            icon: 'info',
            title: 'Error',
            text: responseData.result.message,
          });
        }
      }
      else {
        console.error('Failed to submit form:', response.statusText);
        localStorage.removeItem('user');
      }
    } catch (error) {
      localStorage.removeItem('user');
      console.error('An error occurred:', error);
    } finally {
      setIsVerifying(false); // Re-enable the button after the request completes
    }
  };
  // email verification
  const verifyResendOtp = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(endpointOtpUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        const responseData = await response.json();
        //const resultData = JSON.parse(responseData.result);
        const success = responseData.result.success;
        if (responseData.result.success === 'YES') {

          const otp = responseData.result.emailOtp
          setsetOtp(otp)
          setIsOtpVerified(true);
          setResendTriggered(true)
          setMessage(responseData.message);
          setTimer(60);
          setIsTimerExpired(false);

          Swal.fire({
            icon: 'info',
            title: 'Success',
            text: responseData.result.message,
          });
        }
        if (responseData.result.success === 'NO') {
          localStorage.removeItem('user');
          // Handle if email already exist
          Swal.fire({
            icon: 'info',
            title: 'OOPS',
            text: responseData.result.message,
          });
        }
      }
      else {
        console.error('Failed to submit form:', response.statusText);
        localStorage.removeItem('user');
      }
    } catch (error) {
      localStorage.removeItem('user');
      console.error('An error occurred:', error);
    }
  };
  //email validation
  const validateOtp = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(endPointvalidateOtp, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...formData, otp: setOtp }),
      });
      if (response.ok) {
        const responseData = await response.json();
        const success = responseData.result.success;
        if (responseData.result.success === 'YES') {
          setIsOtpValidated(true);
          setIsValidOTPButton(true);
        }
        if (responseData.result.success === 'NO') {

          setIsValidOTPButton(false);
          setShowSnackbar(true);

        }

      } else {

        console.error('Failed to submit form:', response.statusText);
        localStorage.removeItem('user');
      }
    } catch (error) {
      localStorage.removeItem('user');
      console.error('An error occurred:', error);
    }
  };

  //facebook login
  const responseMessageFacebook = async (response) => {
    const profile = response.data

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const endpoint = '/login_googole';
    const registerUrlGoogle = `${apiUrl}${endpoint}`;

    const formData = {
      username: profile.name,
      useremail: profile.email,
    };
    try {
      const responseValue = await fetch(registerUrlGoogle, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (responseValue.ok) {
        const responseData = await responseValue.json();
        const resultData = JSON.parse(responseData.result);
        if (resultData.success === 'YES') {
          localStorage.setItem('user', JSON.stringify({ user: resultData.username, email: resultData.user_email, user_id: resultData.id }));

          // Redirect to home page after successful login
          window.location.href = '/';
        }
        if (resultData.success === 'NO') {
          localStorage.removeItem('user');
          localStorage.clear();
          // Handle failed login
        }
      } else {
        console.error('Failed to submit form:', response.statusText);
      }
    } catch (error) {
      localStorage.removeItem('user');
      console.error('An error occurred:', error);
      // Handle internal server error
    }

  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formData.username.trim()) {
      Swal.fire({
        icon: 'Error',
        title: 'Error',
        text: 'Name is mandatory',

      })
      return;
    }
    if (!formData.password.trim() && !formData.repassword.trim()) {
      Swal.fire({
        icon: 'Error',
        title: 'Error',
        text: 'Password is Mandatory',

      })
      return;
    }
    try {
      const response = await fetch(registerUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        const responseData = await response.json();
        const resultData = JSON.parse(responseData.result);

        // Handle success or failure based on resultData
        if (resultData.success === 'YES') {
          localStorage.setItem('user', JSON.stringify({ user: resultData.username, email: resultData.email, user_id: resultData.user_id }));
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: resultData.message,
            confirmButtonText: 'Continue'
          }).then((result) => {
            if (result.isConfirmed) {
              if (location.state && location.state.fromCart.fromCheckout) {
                navigate('/cart');
              } else {
                // Redirect to home page after successful login
                window.location.href = '/';
              }
            }
          });
        } else {
          localStorage.removeItem('user');
          Swal.fire({
            icon: 'info',
            title: 'Error',
            text: resultData.message,
          });
        }
      } else {
        console.error('Failed to submit form:', response.statusText);
        localStorage.removeItem('user');
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Internal server error while submit Form.',
        });
      }
    } catch (error) {
      localStorage.removeItem('user');
      console.error('An error occurred:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Internal Sever Error.',
      });
    }
  };
  const responseMessage = async (response) => {
    const decoded = jwtDecode(response.credential)
    const user_email = decoded.email
    const user_name = decoded.name
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const endpoint = '/login_googole';
    const registerUrlGoogle = `${apiUrl}${endpoint}`;
    const formData = {
      username: user_name,
      useremail: user_email,
    };
    try {
      const responseValue = await fetch(registerUrlGoogle, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (responseValue.ok) {
        const responseData = await responseValue.json();
        const resultData = JSON.parse(responseData.result);
        if (resultData.success === 'YES') {
          localStorage.setItem('user', JSON.stringify({ user: resultData.username, email: resultData.user_email, user_id: resultData.id }));

          // Redirect to home page after successful login
          window.location.href = '/';
        }
        if (resultData.success === 'NO') {
          localStorage.removeItem('user');
          localStorage.clear();
          // Handle failed login
        }
      } else {
        console.error('Failed to submit form:', response.statusText);
      }
    } catch (error) {
      localStorage.removeItem('user');
      console.error('An error occurred:', error);
      // Handle internal server error
    }
  };

  const errorMessage = (error) => {
    console.log(error);
  };

  const handlePasswordVisibilityToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordVisibilityToggle = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };


  return (
    <div className="page-container">
      <div className="e_login">
        <div className="login">
          <div className="e_login_content">
            <div className="" style={{ marginBottom: '20px' }}>
              <Link to={dynamicURL}>
                {logoSrc ? <img src={logoSrc} style={{ width: '80px', height: '80px', borderRadius: '50%', border: '1px solid gray' }} alt="Company Logo" /> : <img src={NoLogo} style={{ width: '80px', height: '80px', borderRadius: '50%', border: '1px solid gray' }} alt="No logo" />}
              </Link>
            </div>
            <h1 className="e_head">Sign Up</h1>
            <Box component="form" noValidate onSubmit={handleSubmit}>
              <TextField
                fullWidth
                variant="outlined"
                id="username"
                label="Full Name"
                name="username"
                value={formData.username}
                onChange={handleChange}
                required
                size='small'
              />
              <TextField
                fullWidth
                variant="outlined"
                id="phone"
                label="Phone"
                name="phone"
                value={formData.phone}
                onChange={(e) => handlePhoneChange(e)}
                type="text" // Use text to enforce input limitations effectively
                required
                size="small"
                sx={{ mt: 2, ml: 0 }}
                disabled={isOtpValidated}
                inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*' }} // Enforces numeric input on mobile devices
              />
              <Grid container spacing={1} alignItems="center" >
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="email"
                    type='email'
                    label="Email Address"
                    name="email"
                    value={formData.useremail}
                    onChange={handleChange}
                    required
                    disabled={isOtpValidated}
                    sx={{ mt: 2, ml: 0 }}
                    size='small'
                    className={`mr-2 ${isOtpValidated ? 'verified-email' : ''}`}
                  />
                </Grid>
                <Grid item xs={2} sx={{ mt: 2, ml: 0 }}>
                  <Button
                    variant="contained" // Use "contained" variant for a solid button
                    color="primary" // Set the button color to primary
                    type="submit"
                    onClick={verifyOtp}
                    className="mt-0"
                    disabled={!isValidDomain || isVerifying}
                    // disabled={!isValidDomain}
                    style={{ width: '80px', fontSize: '93%', display: !isOtpVerified ? 'flex' : 'none', backgroundColor: '#375E97' }}
                  >Send
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={verifyResendOtp}
                    className="mt-0"
                    style={{ width: '80px', display: isSendClicked ? 'flex' : 'none', width: '80px' }}
                    disabled={!isTimerExpired || isOtpValidated}
                  >
                    <span id="btn_resend"
                    // style={{ marginLeft: '-27%', fontSize: '93%' }}
                    >ReSend</span>
                  </Button>
                </Grid>
              </Grid>

              {!isValidDomain && <p style={{ color: 'red', fontSize: '75%' }}>*Invalid email</p>}

              {/*{!isValidOTPButton && <p style={{ color: 'red',fontSize:'75%' }}>*Invalid otp</p>}*/}

              <Grid container spacing={2} sx={{ mt: 1, ml: -2 }}>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="passcode"
                    label="OTP"
                    name="passcode"
                    size='small'
                    inputProps={{ maxLength: 4 }}
                    value={formData.passcode}
                    onChange={handleChangeOTP}
                    required style={{ display: isOtpVerified ? 'block' : 'none' }}
                    disabled={isOtpValidated}
                    className={`mr-2 ${!isValidOTPButton ? 'unverified-email' : ''}`}

                  />
                  {/*{!isValidOTPButton && <p style={{ color: 'red',fontSize:'75%' }}>*Invalid otp</p>}*/}
                </Grid>
                <Grid item xs={2} sx={{ mt: 0, ml: -1 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="mt-0"
                    onClick={validateOtp} disabled={isOtpValidated}
                    style={{ width: '80px', display: isOtpVerified ? 'block' : 'none', textAlign: 'right' }}
                  >
                    <span id="abcde"
                    // style={{ marginLeft: '-24%', fontSize: '93%' }}
                    >Verify</span>
                  </Button>
                </Grid>
              </Grid>

              <div id="xyze" style={{ bottom: '10%', marginLeft: '69%', position: 'relative', marginTop: '1px' }}>
                {isOtpVerified && (
                  <span >
                    {/*{isTimerExpired ? '' : `<Timericon/>: ${timer}`}*/}
                    {isTimerExpired || isOtpValidated ? '' : <>00: {timer}</>}
                    {/*{ isTimerExpired ? '': Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}*/}
                  </span>
                )}
              </div>

              {/* <TextField
                fullWidth
                variant="outlined"
                id="password"
                label="Password"
                name="password"
                size='small'
                value={formData.password}
                onChange={handleChange}
                type="password"
                required
                style={{ display: isOtpValidated ? 'block' : 'none' }}
                sx={{ mt: 2, ml: 0 }}
              /> */}
              <TextField
                fullWidth
                variant="outlined"
                id="password"
                label="Password"
                name="password"
                size="small"
                value={formData.password}
                onChange={handleChange}
                type={showPassword ? 'text' : 'password'}
                style={{ display: isOtpValidated ? 'block' : 'none' }}
                required
                sx={{ mt: 2, ml: 0 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ marginLeft: 'auto' }}>
                      <IconButton onClick={handlePasswordVisibilityToggle} edge="end">
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {/* <TextField
                fullWidth
                variant="outlined"
                id="repassword"
                label="Confirm Password"
                name="repassword"
                size='small'
                value={formData.confirm_password}
                onChange={handleChange}
                type="password"
                required
                style={{ display: isOtpValidated ? 'block' : 'none' }}
                sx={{ mt: 2, ml: 0 }}
              /> */}

              <TextField
                fullWidth
                variant="outlined"
                id="repassword"
                label="Confirm Password"
                name="repassword"
                size="small"
                value={formData.confirm_password}
                onChange={handleChange}
                type={showConfirmPassword ? 'text' : 'password'}
                required
                sx={{ mt: 2, ml: 0 }}
                style={{ display: isOtpValidated ? 'block' : 'none' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ marginLeft: 'auto' }}>
                      <IconButton onClick={handleConfirmPasswordVisibilityToggle} edge="end">
                        {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <span className="clearfix" />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit}
                style={{ display: isOtpValidated ? 'block' : 'none' }}
              >
                Sign Up
              </Button>
            </Box>
            {/* <span className="loginwith">Or Connect with</span>
            <br />
            <div className="social-login">
              <GoogleLogin className="social-btn google-btn" onSuccess={responseMessage}
                onError={errorMessage}><FcGoogle /> Continue with Google</GoogleLogin>
              <LoginSocialFacebook appId="1078994883190453" className="social-btn facebook-btn" onResolve={responseMessageFacebook}
                onReject={(error) => {
                  console.log(error);
                }}><FaFacebook /> Continue with Facebook</LoginSocialFacebook>
            </div> */}
            {/* <div className="login-container">
              <GoogleLogin
                className="custom-google-login"
                title="Login"
                onSuccess={responseMessage}
                onError={errorMessage}
              />
              <LoginSocialFacebook
                appId="1078994883190453"
                onResolve={responseMessageFacebook}
                onReject={(error) => {
                  console.log(error);
                }}
              >
                <FacebookLoginButton style={{ width: '210px', height: '39px' }}>Facebook</FacebookLoginButton>
              </LoginSocialFacebook>
            </div> */}
            <br />
            <div className="e_register">
              <p>
                Already have an account?{" "}
                <span>
                  <Link to='/login'>Sign In</Link>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  );

}

import React, { useMemo, useState, useEffect } from "react";
import "./FilteredCategoryProduct.css";
import { useProductContext } from "../../Contexts/ProductContext";
import { useCartContext } from '../../Contexts/CartContext';
import { IoMdCart } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { FaChevronRight } from "react-icons/fa";
import Button from '@mui/material/Button';
import NoImg from './No-Image.png';

export const FilteredCategoryProduct = ({ selectedCategory }) => {
    const { products } = useProductContext();
    const { addToCart, decreaseQuantity, removeFromCart, increaseQuantity, cartItems } = useCartContext();
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

    const [visibleCount, setVisibleCount] = useState(10); // Show 10 items initially

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const decodeBase64Image = (base64) => {
        const decodedImage = new Image();
        decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
        return decodedImage.src;
    };

    const getParentCategory = (subcategory) => {
        const product = products.find(
            (product) => product.category_hierarchy[1] === subcategory
        );
        return product ? product.category_hierarchy[0] : null;
    };

    const parentCategory = getParentCategory(selectedCategory);

    const filteredProducts = useMemo(() => {
        return products.filter(
            product => product.category_hierarchy[1] === selectedCategory
        );
    }, [products, selectedCategory]);

    const visibleProducts = filteredProducts.slice(0, visibleCount);

    const truncateNameForURL = (name, maxLength) => {
        const words = name.split(' ');
        if (words.length <= maxLength) {
            return words.join('-');
        }
        return words.slice(0, maxLength).join('-');
    };

    const handleSeeMore = () => {
        if (parentCategory) {
            navigate(`/${encodeURIComponent(parentCategory)}`, {
                state: { selectedCategory: selectedCategory },
            });
        }
        // setVisibleCount((prevCount) => prevCount + 8); // Show 8 more items on click
    };

    const addToCartWithApi = async (productDetail) => {
        try {
            addToCart(productDetail);

            const currentUser = JSON.parse(localStorage.getItem('user'));

            if (currentUser && currentUser.user) {
                const response = await fetch(`${apiUrl}/add_to_cart`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: currentUser.user_id, cartItems: productDetail }),
                });

                if (!response.ok) {
                    throw new Error('Failed to add product to cart');
                }

            } else {
                console.error('User not logged in'); // Log an error if user is not logged in
            }
        } catch (error) {
            console.error('Error adding product to cart:', error);
        }
    };

    const removeFromCartWithApi = async (productId) => {
        try {
            handleRemoveFromCart(productId);

            const currentUser = JSON.parse(localStorage.getItem('user'));

            if (currentUser && currentUser.user) {
                const response = await fetch(`${apiUrl}/remove_from_cart`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: currentUser.user_id, productId }),
                });

                if (!response.ok) {
                    throw new Error('Failed to remove product from cart');
                }

            } else {
                console.error('User not logged in');
            }
        } catch (error) {
            console.error('Error removing product from cart:', error);
        }
    };

    const handleIncreaseQuantity = async (productId) => {
        const product = products.find(p => p.id === productId);
        const productInCart = cartItems.find(item => item.id === productId);
        increaseQuantity(productId);

        const currentUser = JSON.parse(localStorage.getItem('user'));

        if (currentUser && currentUser.user_id) {
            const response = await fetch(`${apiUrl}/increase_product_qty_in_cart`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: currentUser.user_id, productId }),
            });

            if (response.ok) {
                const responseData = await response.json();
            } else {
                console.error('Failed to increase quantity');
            }
        } else {
            console.error('User not logged in');
        }
    };

    const handleDecreaseQuantityBackend = async (productId) => {
        decreaseQuantity(productId); // Update the quantity in the local state

        const currentUser = JSON.parse(localStorage.getItem('user'));

        // Check if the current user is logged in
        if (currentUser && currentUser.user) {
            try {
                // Send a POST request to the backend to update the quantity
                const response = await fetch(`${apiUrl}/decrease_product_qty_in_cart`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: currentUser.user_id, productId }),
                });

                if (!response.ok) {
                    throw new Error('Failed to decrease quantity');
                }

                const responseData = await response.json();
            } catch (error) {
                console.error('Error decrease quantity:', error);
            }
        }
    };

    const handleRemoveFromCart = (productId) => {
        removeFromCart(productId);
    };

    return (
        <>
            <div className="category-section">
                <div className="products-grid">
                    {visibleProducts.map((item) => {
                        const productInCart = cartItems.find((itemInCart) => itemInCart.id === item.id);
                        return (
                            <div key={item.id} className="product-main-card">

                                <div className="category-product-card">
                                    <Link
                                        to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}>
                                        {item.image ?
                                            <img
                                                src={decodeBase64Image(item.image)}
                                                alt={item.name}
                                                loading="lazy"
                                                className="img-fluid"
                                                style={{
                                                    objectFit: 'cover',
                                                    borderRadius: 0,
                                                    height: '200px',
                                                    width: '100%',
                                                }}
                                            /> :
                                            <img
                                                src={NoImg}
                                                alt={item.name}
                                                loading="lazy"
                                                className="img-fluid"
                                                style={{
                                                    objectFit: 'cover',
                                                    borderRadius: 0,
                                                    height: '200px',
                                                    width: '100%',
                                                }}
                                            />
                                        }

                                    </Link>
                                    <div className="shop-content">
                                        <h3>{item.name}</h3>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <span className="old-price" style={{ fontSize: '16px', color: '#0096FF', fontWeight: '600' }}>
                                                ₹{item.price}
                                            </span>
                                            {productInCart ? (
                                                <>
                                                    <div className="qty-buttons pr_dtls">
                                                        <span className='pr_dtls'>
                                                            <span style={{ border: '1px solid #ff7043', padding: isMobileView ? '0px 0px' : '3px 2px', }}>
                                                                <button className="minus" onClick={() => {
                                                                    if (productInCart.quantity === 1) {
                                                                        removeFromCartWithApi(item.id);
                                                                    } else {
                                                                        handleDecreaseQuantityBackend(item.id);
                                                                    }
                                                                }}>-</button>
                                                                <span className="qty" style={{ padding: '7px' }}>{productInCart.quantity}</span>
                                                                <button className="plus" onClick={() => handleIncreaseQuantity(item.id)}>+</button></span></span>
                                                    </div>
                                                    {/* <div className="quantity-control">
                                                        <>
                                                            <Button variant="outlined" sx={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', borderRadius: '30px', borderColor: '#ff7043', color: '#ff7043' }} onClick={() => {
                                                                if (productInCart.quantity === 1) {
                                                                    removeFromCartWithApi(item.id);
                                                                } else {
                                                                    handleDecreaseQuantityBackend(item.id);
                                                                }
                                                            }}>-</Button>
                                                            <span style={{ padding: '7px' }}>{productInCart.quantity}</span>
                                                        </>
                                                        <Button variant="outlined" sx={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', borderRadius: '30px', borderColor: '#ff7043', color: '#ff7043' }} onClick={() => handleIncreaseQuantity(item.id)}>+</Button>
                                                    </div> */}
                                                </>
                                            ) : (
                                                <div style={{ border: '0.5px solid #ff7043', borderRadius: '50%', padding: '2px 6px', height: '30px', width: '30px', justifyContent: 'center', alignItems: 'center' }} onClick={() => addToCartWithApi(item)}>
                                                    <IoMdCart style={{ fill: '#ff7043' }} />
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                    })}
                </div>
                {filteredProducts.length > visibleCount && (
                    <div className="see-more-container">
                        <button className="see-more-button" onClick={handleSeeMore}>
                            See More <FaChevronRight />
                        </button>
                    </div>
                )}
            </div >
        </>
    );
};

export default FilteredCategoryProduct;
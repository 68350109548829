import React, { useState, useEffect } from 'react';
import './CategoryCards.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useProductContext } from '../../Contexts/ProductContext';
import image from './Ecommerce-Doodle-Banner.jpg';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Slider from '@mui/material/Slider';
import MobileFooter from '../MobileFooter/MobileFooter';
import Footer from '../Footer/Footer';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Snackbar from '@mui/material/Snackbar';
import Swal from 'sweetalert2';

export default function CategoryCards({ searchTerm }) {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [categoryImage, setCategoryImage] = useState(null);
  const { products } = useProductContext();
  const location = useLocation();
  const [wishlist, setWishlist] = useState([]);
  const wishlist_endpoint = '/add_to_wishlist';
  const wishlistUrl = `${apiUrl}${wishlist_endpoint}`;
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const removeFromWishlistUrl = `${apiUrl}/remove_from_wishlist`;
  const navigate = useNavigate();

  const category = location.state?.category || decodeURIComponent(location.pathname.split('/').pop());

  const subcategoryFromState = location.state?.selectedCategory || null;

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [maxPrice, setMaxPrice] = useState(0);
  const [priceRange, setPriceRange] = useState([0, 0]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showRemoveSnackbar, setShowRemoveSnackbar] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 5; // Show 5 products per page

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const filtered = products.filter(product =>
      product.category_hierarchy?.[0] === category && product.on_website
    );
    setFilteredProducts(filtered);
  }, [products, category]);

  const categories = Array.from(new Set(filteredProducts
    .filter(product => product.category_hierarchy?.length > 1)
    .map(product => product.category_hierarchy[1])
  ));

  const brands = Array.from(new Set(filteredProducts.map(product => product.brand)));

  const decodeBase64Image = (base64) => {
    const decodedImage = new Image();
    decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
    return decodedImage.src;
  };

  const [selectedCategory, setSelectedCategory] = useState(subcategoryFromState ? [subcategoryFromState] : []);
  const [selectedBrands, setSelectedBrands] = useState([]);

  // const handleCategorySelect = (selectedCat) => {
  //   setSelectedCategory(selectedCategory === selectedCat ? null : selectedCat);
  // };

  const handleCategorySelect = (selectedCat) => {
    setSelectedCategory(prevSelectedCategories =>
      prevSelectedCategories.includes(selectedCat)
        ? prevSelectedCategories.filter(cat => cat !== selectedCat)
        : [...prevSelectedCategories, selectedCat]
    );
  };


  const handleBrandSelect = (selectedBrand) => {
    setSelectedBrands((prevSelectedBrands) =>
      prevSelectedBrands.includes(selectedBrand)
        ? prevSelectedBrands.filter((brand) => brand !== selectedBrand)
        : [...prevSelectedBrands, selectedBrand]
    );
  };

  const handlePriceRangeChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  useEffect(() => {
    const filteredByCategory = filteredProducts.filter(product =>
      selectedCategory.length > 0 ? selectedCategory.includes(product.category_hierarchy?.[1]) : true
    );
    const maxProductPrice = Math.max(...filteredByCategory.map(product => product.price), 0);
    setMaxPrice(maxProductPrice);
    setPriceRange([0, maxProductPrice]);
  }, [filteredProducts, selectedCategory]);

  const displayedProducts = filteredProducts.filter(product => {
    const matchesCategory = selectedCategory.length > 0 ? selectedCategory.includes(product.category_hierarchy?.[1]) : true;
    const matchesBrand = selectedBrands.length > 0 ? selectedBrands.includes(product.brand) : true;
    const matchesPriceRange = product.price >= priceRange[0] && product.price <= priceRange[1];
    const matchesSearchTerm = searchTerm ? product.name.toLowerCase().includes(searchTerm.toLowerCase()) : true;
    return matchesCategory && matchesBrand && matchesPriceRange && matchesSearchTerm;
  });

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = displayedProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const totalPages = Math.ceil(displayedProducts.length / productsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  useEffect(() => {
    const sendPostRequest = async () => {
      try {
        const response = await fetch(`${apiUrl}/get_category_image`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ category: category })
        });

        if (!response.ok) {
          throw new Error('Failed to send POST request');
        }

        const data = await response.json();
        setCategoryImage(data.result);
      } catch (error) {
        console.error('Error sending POST request:', error.message);
      }
    };

    sendPostRequest();
  }, [category]);

  useEffect(() => {
    const toggleSidebar = () => {
      const sidemenu = document.querySelector(".filter_sidebar");
      const closeX = document.querySelector(".filter_close");
      const overlay = document.querySelector(".filter_overlay");

      if (sidemenu && closeX && overlay) {
        sidemenu.classList.toggle("active");
        closeX.classList.toggle("active");
        overlay.classList.toggle("active");
      }
    };

    const closeSidebar = () => {
      const sidemenu = document.querySelector(".filter_sidebar");
      const closeX = document.querySelector(".filter_close");
      const overlay = document.querySelector(".filter_overlay");

      if (sidemenu && closeX && overlay) {
        sidemenu.classList.remove("active");
        closeX.classList.remove("active");
        overlay.classList.remove("active");
      }
    };

    const filterShow = document.getElementById("filter_show");
    const filterClose = document.querySelector(".filter_close");

    if (filterShow) {
      filterShow.addEventListener("click", toggleSidebar);
    }
    if (filterClose) {
      filterClose.addEventListener("click", closeSidebar);
    }

    return () => {
      if (filterShow) {
        filterShow.removeEventListener("click", toggleSidebar);
      }
      if (filterClose) {
        filterClose.removeEventListener("click", closeSidebar);
      }
    };
  }, []);

  const truncateNameForURL = (name, maxLength) => {
    const words = name.split(' ');
    if (words.length <= maxLength) {
      return words.join('-');
    }
    return words.slice(0, maxLength).join('-');
  };

  const fetchWishlist = async () => {
    const currentUser = JSON.parse(localStorage.getItem('user'));

    const response = await fetch(`${apiUrl}/check_wishlist/user/app`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ user_id: currentUser.user_id })
    });

    if (response.ok) {
      const result = await response.json();
      console.log("result of wislist in category card ===========", result);
      const parsedResult = JSON.parse(result.result);
      setWishlist(parsedResult.wishlist);
      console.log("wislist in category card ===========", parsedResult.wishlist);
    } else {
      console.error('Failed to fetch wishlist');
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchWishlist();
    }
  }, []);

  const wishlistNames = new Set(wishlist.map(item => item.product_name));

  const addToWishlist = async (productDetail) => {
    try {
      const currentUserData = localStorage.getItem('user');
      const currentUser = JSON.parse(currentUserData);

      if (currentUser && currentUser.user_id) {
        const response = await fetch(wishlistUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, product_detail: productDetail }),
        });
        if (!response.ok) {
          throw new Error('Failed to add to wishlist');
        }
        setShowSnackbar(true);
        fetchWishlist();
      } else {
        console.error('User ID not found in currentUser data');
        Swal.fire({
          html: `
          Hi there! Log in to your account or create one today to enjoy a personalized shopping experience!
          `,
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: `
            Login
          `,
          confirmButtonAriaLabel: "Login",
          cancelButtonText: `
            Register
          `,
          cancelButtonAriaLabel: "Register",
          customClass: {
            confirmButton: 'swal-button-login',
            cancelButton: 'swal-button-register'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/login'; // Redirect to login page
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            window.location.href = '/register'; // Redirect to register page
          }
        });
        // navigate('/login');
      }
    } catch (error) {
      console.error('Error adding to wishlist:', error);
    }
  };

  const removeFromWishlist = async (productDetail) => {
    try {
      const currentUserData = localStorage.getItem('user');
      const currentUser = JSON.parse(currentUserData);

      if (currentUser && currentUser.user_id) {
        const response = await fetch(removeFromWishlistUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, product_detail: productDetail }),
        });

        if (!response.ok) {
          throw new Error('Failed to remove from wishlist');
        }
        setShowRemoveSnackbar(true);
        fetchWishlist();
      } else {
        console.error('User ID not found in currentUser data');
        navigate('/login');
      }
    } catch (error) {
      console.error('Error removing from wishlist:', error);
    }
  };

  return (
    <>
      <div className='common-mt'>
        <section className="breadcrumb-area">
          <div className="ratio">
            {/* {categoryImage ? (
              <img
                src={decodeBase64Image(categoryImage)}
                alt="Category Imagewecfdc"
                className="img-fluid ls-is-cached lazyloaded"
                width={1920}
                height={300.0}
                decoding="async"
                loading="lazy"
                sizes="100vw"
              />
            ) : ( */}
            <img
              src={image}
              alt=""
              className="img-fluid ls-is-cached lazyloaded"
              width={1920}
              height={300.0}
              decoding="async"
              loading="lazy"
              sizes="100vw"
            />
            {/* )} */}
          </div>
          <div className="container">
            <div className="row">
              <div className="col-">
                <div className="breadcrumb-index">
                  <ul className="breadcrumb-list">
                    <li className="breadcrumb-item">
                      <a href="/" title="Back to the home page">
                        Home
                      </a>
                    </li>
                  </ul>
                  <div className="breadcrumb-title">
                    <h1>{category} products</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="main-content-wrap" style={{ height: 'auto' }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="shop-top-bar">
                  <div className="category_filter">
                    <div id="filter_show"><FilterAltOutlinedIcon />Filter</div>
                    <div className="filter_sidebar">
                      <div className="filter_close filter_close-btn" />
                      <h2 className="pro-grli-title">Categories</h2>
                      <div className="filter_menu-bar">
                        <div className="e-desk-left">
                          <ul>
                            {categories.map((cat, index) => (
                              <li key={index}>
                                <input type="checkbox"
                                  checked={selectedCategory.includes(cat)}
                                  onChange={() => handleCategorySelect(cat)}
                                  id={cat}
                                />
                                <label htmlFor={cat}>{cat}</label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      <h2 className="pro-grli-title">Price</h2>
                      <div className="filter_menu-bar">
                        <div className="e-desk-left">
                          <Slider
                            value={priceRange}
                            onChange={handlePriceRangeChange}
                            valueLabelDisplay="auto"
                            min={0}
                            max={maxPrice} // Dynamic maximum value
                            disableSwap
                          />
                          <p>{maxPrice}</p>
                        </div>
                      </div>
                    </div>
                    <div className="filter_overlay" />
                  </div>
                </div>
                <div className="pro-grli-wrapper left-side-wrap" style={{ height: '100%', marginBottom: '40px' }}>
                  {/* <div className="mobile-sidebar-wrap"> */}

                  <div className="pro-grli-wrap product-grid">
                    <div className="pro-grlis">
                      <h2 className="pro-grli-title">Results</h2>
                    </div>
                    {/* <div className="mobile-sidebar-wrap"> */}
                    {/* <div className="product-sidebar">
                        <ul className="sidebar-list"> */}
                    {/* {categories.map((category, index) => ( */}
                    {/* <li className="sidebar-item">
                            <a href="">ookokokoko</a>
                          </li> */}
                    {/* ))} */}
                    {/* </ul>
                      </div> */}

                    <div className="ctg_grid-area gdd_ppppppp mobile-sidebar-wrap">
                      {currentProducts.map((product, index) => {
                        const isInWishlist = wishlistNames.has(product.name);
                        return (

                          <div className="categories-product-card" key={index}>
                            <Link
                              to={`/${product.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(product.name, 4))}-${encodeURIComponent(product.id)}`}
                            >
                              <div className="image-container">
                                <img
                                  src={decodeBase64Image(product.image)}
                                  alt={product.name}
                                />
                              </div>
                            </Link>

                            <div className="content">
                              {/* <h4 className="subtitle">Subtitle</h4> */}
                              <Link
                                to={`/${product.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(product.name, 4))}-${encodeURIComponent(product.id)}`}
                              >
                                <h2 className="product-name">{product.name}</h2>
                              </Link>
                              <p className="category-description">
                                {product.description}
                              </p>
                              <p className="price">₹{product.price}</p>
                            </div>


                            <div className="wishlist-icon">
                              {isInWishlist ? (
                                <FavoriteIcon style={{ color: 'red', marginLeft: '7px' }} onClick={() => removeFromWishlist(product)} />
                              ) : (
                                <FavoriteBorderIcon style={{ color: 'red', marginLeft: '7px' }} onClick={() => addToWishlist(product)} />
                              )}
                            </div>

                          </div>

                        )
                      })}

                      {/* {displayedProducts.map((product, index) => (
                        <div className="ctg-formation ctg_grid" key={index}>
                          <div className="ctg_grid-cont"
                            style={{
                              position: 'relative',
                              backgroundColor: product.sold ? 'rgba(240, 240, 240, 0.9)' : 'white',
                              border: product.sold ? '1px solid rgba(200, 200, 200, 0.5)' : 'none',
                              // borderStyle: 'outset',
                            }}
                          >
                            {product.sold && (
                              <div className="sold-out-ribbon">Sold Out</div>
                            )}
                            <Link
                              to={`/${product.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(product.name, 4))}-${encodeURIComponent(product.id)}`}
                            >
                              <div className="ctg_grid-akrcont" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 'fit-content' }}>
                                  <img
                                    src={decodeBase64Image(product.image)}
                                    style={{
                                      width: isMobileView ? "80%" : "150px",
                                      height: isMobileView ? "130px" : "150px",
                                      margin: "10px 20px",
                                      display: "block",
                                      objectFit: "contain",
                                      opacity: 1,
                                      aspectRatio: "1 / 1"
                                    }}
                                    alt={product.name}
                                  />
                                </div>
                                <div className="ctg_grif-content">
                                  <div className="ctg_grid-title" style={{ textAlign: 'left' }}>
                                    <h2 className="product-name">{product.name}</h2>
                                    <span className="e-price-name">
                                      ₹{product.price}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      ))} */}
                    </div>
                    {totalPages > 1 &&
                      <div className="pagination">
                        {Array.from({ length: totalPages }, (_, index) => (
                          <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            className={currentPage === index + 1 ? 'active' : ''}
                          >
                            {index + 1}
                          </button>
                        ))}
                      </div>
                    }
                    {/* </div> */}
                  </div>
                  <div className="pro-grli-wrap product-sidebar">
                    <div className="pro-grlis">
                      <h2 className="pro-grli-title">Categories</h2>
                    </div>
                    <div className="filter_menu-bar">
                      <div className="e-desk-left">
                        <ul>
                          {categories.map((cat, index) => (
                            <li key={index}>
                              <input type="checkbox"
                                // checked={selectedCategory === cat}
                                checked={selectedCategory.includes(cat)}
                                onChange={() => handleCategorySelect(cat)} />
                              <label style={{ fontSize: '16px', fontWeight: '400' }} htmlFor={cat}>{cat}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <h2 className="pro-grli-title">Price</h2>
                    <div className="filter_menu-bar">
                      <div className="e-desk-left">
                        <Slider
                          value={priceRange}
                          onChange={handlePriceRangeChange}
                          valueLabelDisplay="auto"
                          min={0}
                          max={maxPrice} // Dynamic maximum value
                          disableSwap
                        />
                        {/* <div className="price-range-display">
                          <div className='price-range'>{priceRange[0]}</div>
                          <div className='price-range'>{priceRange[1]}</div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div >
      <Footer />
      {isMobileView && (
        <MobileFooter />
      )
      }

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showSnackbar}
        autoHideDuration={1000}
        onClose={() => setShowSnackbar(false)}
        message="Added to Wishlist"
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showRemoveSnackbar}
        autoHideDuration={1000}
        onClose={() => setShowRemoveSnackbar(false)}
        message="Removed from Wishlist"
      />
    </>
  );
}

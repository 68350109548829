// // TrendingItemsCarousel.js
// import React from 'react';
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import { useProductContext } from '../../Contexts/ProductContext';
// import { Link } from 'react-router-dom';
// import './Trending.css';

// const TrendingItemsCarousel = () => {
//   const { products } = useProductContext();

//   const decodeBase64Image = (base64) => {
//     const decodedImage = new Image();
//     decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
//     return decodedImage.src;
//   };

//   const trendingProducts = products.filter((product) => product.trending);


//   const carouselOptions = {
//     items: 4,
//     margin: 20,
//     // nav: true,
//     dots: false,
//     responsive: {
//       0: {
//         items: 2 // Show only one item in mobile view
//       },
//       479: {
//         items: 2 // Show two items in tablet view
//       },
//       700: {
//         items: 3
//       },
//       800: {
//         items: 4 // Show four items in desktop view
//       },
//       1093: {
//         items: 5
//       }
//     }
//   };

//   const truncateNameForURL = (name, maxLength) => {
//     const words = name.split(' ');
//     if (words.length <= maxLength) {
//       return words.join('-'); // If the number of words is less than or equal to maxLength, use all words
//     }
//     return words.slice(0, maxLength).join('-'); // Otherwise, use only the first maxLength words
//   };

//   return (
//     <div className="carousel-box pt-3 pb-5">
//       <div className="container-fluid">
//         <h2 className="text-start mb-5">Shop Our Trending Items</h2>
//         <OwlCarousel className="owl-carousel3 owl-theme" {...carouselOptions}>
//           {trendingProducts.map((item, index) => (
//             <div key={index} className="item">
//               <div className="shop1">
//                 <Link
//                   to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
//                   style={{ textDecoration: 'none', color: 'inherit' }}>
//                   <img
//                     src={decodeBase64Image(item.image)}
//                     alt=""
//                     title=""
//                     loading="lazy"
//                     className="img-fluid"
//                     style={{
//                       objectFit: 'contain',
//                       borderRadius: 0,
//                     }}
//                   />
//                   <div className=""></div>
//                   <div className="shop-content">
//                     <h3>{item.name}</h3>
//                     <span className="old-price" style={{ fontSize: '24px', color: 'black' }}>
//                       <span style={{
//                         fontSize: '14px',
//                         position: 'relative',
//                         top: '-7px',
//                         fontSize: '13px',
//                         paddingRight: '3px'
//                       }}>$</span>{item.price}
//                     </span>
//                   </div>
//                 </Link>
//               </div>
//             </div>
//           ))}
//         </OwlCarousel>
//       </div>
//     </div>
//   );
// };

// export default TrendingItemsCarousel;




// import React from 'react';
// import Slider from 'react-slick';
// import { useProductContext } from '../../Contexts/ProductContext';
// import { Link } from 'react-router-dom';
// import './Trending.css';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";


// // Custom arrows
// const CustomPrevArrow = (props) => {
//   const { className, onClick } = props;
//   return (
//     <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
//       <FaChevronCircleLeft style={{ color: '#4f73f7', fontSize: '28px' }} />
//     </div>
//   );
// };

// const CustomNextArrow = (props) => {
//   const { className, onClick } = props;
//   return (
//     <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
//       <FaChevronCircleRight style={{ color: '#4f73f7', fontSize: '28px' }} />
//     </div>
//   );
// };

// const TrendingItemsCarousel = () => {
//   const { products } = useProductContext();

//   const decodeBase64Image = (base64) => {
//     const decodedImage = new Image();
//     decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
//     return decodedImage.src;
//   };

//   const trendingProducts = products.filter((product) => product.trending);

//   const truncateNameForURL = (name, maxLength) => {
//     const words = name.split(' ');
//     if (words.length <= maxLength) {
//       return words.join('-');
//     }
//     return words.slice(0, maxLength).join('-');
//   };

//   const sliderSettings = {
//     dots: false,
//     infinite: false,
//     speed: 500,
//     slidesToShow: 5,
//     slidesToScroll: 1,
//     arrows: true,
//     prevArrow: <CustomPrevArrow />,
//     nextArrow: <CustomNextArrow />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 4,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <div className="trending-carousel-box pt-3 pb-5">
//       <div className="container-fluid">
//         <h2 className="text-start mb-5">Shop Our Trending Items</h2>
//         <Slider {...sliderSettings}>
//           {trendingProducts.map((item, index) => (
//             <div key={index} className="item">
//               <div className="shop1">
//                 <Link
//                   to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
//                   style={{ textDecoration: 'none', color: 'inherit' }}>
//                   <img
//                     src={decodeBase64Image(item.image)}
//                     alt={item.name}
//                     loading="lazy"
//                     className="img-fluid"
//                     style={{
//                       objectFit: 'contain',
//                       borderRadius: 0,
//                       height: '150px',
//                       width: '100%',
//                     }}
//                   />
//                   <div className="shop-content">
//                     <h3>{item.name}</h3>
//                     <span className="old-price" style={{ fontSize: '24px', color: 'black' }}>
//                       <span style={{
//                         fontSize: '14px',
//                         position: 'relative',
//                         top: '-7px',
//                         paddingRight: '3px'
//                       }}>$</span>{item.price}
//                     </span>
//                   </div>
//                 </Link>
//               </div>
//             </div>
//           ))}
//         </Slider>
//       </div>
//     </div>
//   );
// };

// export default TrendingItemsCarousel;






import React, { useMemo, useState, useEffect } from "react";
import { useProductContext } from '../../Contexts/ProductContext';
import { useCartContext } from '../../Contexts/CartContext';
import { Link } from 'react-router-dom';
import './Trending.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { IoMdCart } from "react-icons/io";

// Custom arrows
const CustomPrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
      <FaChevronCircleLeft style={{ color: '#375E97', fontSize: '28px' }} />
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ display: 'block', zIndex: 1 }}>
      <FaChevronCircleRight style={{ color: '#375E97', fontSize: '28px' }} />
    </div>
  );
};

const TrendingItemsCarousel = () => {
  const { products } = useProductContext();
  const { addToCart, decreaseQuantity, removeFromCart, increaseQuantity, cartItems } = useCartContext();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const decodeBase64Image = (base64) => {
    const decodedImage = new Image();
    decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
    return decodedImage.src;
  };

  const trendingProducts = products.filter((product) => product.trending);

  if (trendingProducts.length === 0) {
    return null;
  }

  const truncateNameForURL = (name, maxLength) => {
    const words = name.split(' ');
    if (words.length <= maxLength) {
      return words.join('-');
    }
    return words.slice(0, maxLength).join('-');
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Function to group products into chunks of 4
  const groupProductsForMobile = (products) => {
    const groupedProducts = [];
    for (let i = 0; i < products.length; i += 4) {
      groupedProducts.push(products.slice(i, i + 4));
    }
    return groupedProducts;
  };

  const mobileGroupedProducts = groupProductsForMobile(trendingProducts);

  const addToCartWithApi = async (productDetail) => {
    try {
      addToCart(productDetail);

      const currentUser = JSON.parse(localStorage.getItem('user'));

      if (currentUser && currentUser.user) {
        const response = await fetch(`${apiUrl}/add_to_cart`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, cartItems: productDetail }),
        });

        if (!response.ok) {
          throw new Error('Failed to add product to cart');
        }

      } else {
        console.error('User not logged in'); // Log an error if user is not logged in
      }
    } catch (error) {
      console.error('Error adding product to cart:', error);
    }
  };

  const removeFromCartWithApi = async (productId) => {
    try {
      handleRemoveFromCart(productId);

      const currentUser = JSON.parse(localStorage.getItem('user'));

      if (currentUser && currentUser.user) {
        const response = await fetch(`${apiUrl}/remove_from_cart`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, productId }),
        });

        if (!response.ok) {
          throw new Error('Failed to remove product from cart');
        }

      } else {
        console.error('User not logged in');
      }
    } catch (error) {
      console.error('Error removing product from cart:', error);
    }
  };

  const handleIncreaseQuantity = async (productId) => {
    const product = products.find(p => p.id === productId);
    const productInCart = cartItems.find(item => item.id === productId);
    increaseQuantity(productId);

    const currentUser = JSON.parse(localStorage.getItem('user'));

    if (currentUser && currentUser.user_id) {
      const response = await fetch(`${apiUrl}/increase_product_qty_in_cart`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: currentUser.user_id, productId }),
      });

      if (response.ok) {
        const responseData = await response.json();
      } else {
        console.error('Failed to increase quantity');
      }
    } else {
      console.error('User not logged in');
    }
  };

  const handleDecreaseQuantityBackend = async (productId) => {
    decreaseQuantity(productId); // Update the quantity in the local state

    const currentUser = JSON.parse(localStorage.getItem('user'));

    // Check if the current user is logged in
    if (currentUser && currentUser.user) {
      try {
        // Send a POST request to the backend to update the quantity
        const response = await fetch(`${apiUrl}/decrease_product_qty_in_cart`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, productId }),
        });

        if (!response.ok) {
          throw new Error('Failed to decrease quantity');
        }

        const responseData = await response.json();
      } catch (error) {
        console.error('Error decrease quantity:', error);
      }
    }
  };

  const handleRemoveFromCart = (productId) => {
    removeFromCart(productId);
  };

  // pb-5 classname
  return (
    <div className="trending-carousel-box pt-3 ">
      {trendingProducts !== 0 && <div className="container-fluid" style={{ padding: 0 }}>
        <h2 className="text-center mb-5">Shop Our Trending Items</h2>

        {/* Desktop and Tablet View */}
        <div className="trending-desktop-carousel">
          <Slider {...sliderSettings}>
            {trendingProducts.map((item, index) => {
              const productInCart = cartItems.find((itemInCart) => itemInCart.id === item.id);
              return (
                <div key={index} className="item">
                  <div className="shop2">
                    <Link
                      to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
                      style={{ textDecoration: 'none', color: 'inherit' }}>
                      <img
                        src={decodeBase64Image(item.image)}
                        alt={item.name}
                        loading="lazy"
                        className="img-fluid"
                        style={{
                          objectFit: 'cover',
                          borderRadius: 0,
                          height: '200px',
                          width: '100%',
                        }}
                      />
                    </Link>
                    <div className="shop-content">
                      <h3>{item.name}</h3>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span className="old-price" style={{ fontSize: '16px', color: '#0096FF', fontWeight: '600' }}>
                          ₹{item.price}
                        </span>
                        {productInCart ? (
                          <>

                            <div className="qty-buttons pr_dtls">
                              <span className='pr_dtls'>
                                <span style={{ border: '1px solid #ff7043', padding: isMobileView ? '0px 0px' : '3px 2px', }}>
                                  <button className="minus" onClick={() => {
                                    if (productInCart.quantity === 1) {
                                      removeFromCartWithApi(item.id);
                                    } else {
                                      handleDecreaseQuantityBackend(item.id);
                                    }
                                  }}>-</button>
                                  <span className="qty" style={{ padding: '7px' }}>{productInCart.quantity}</span>
                                  <button className="plus" onClick={() => handleIncreaseQuantity(item.id)}>+</button></span></span>
                            </div>
                          </>
                        ) : (
                          <div style={{ border: '0.5px solid #ff7043', borderRadius: '50%', padding: '2px 6px', height: '30px', width: '30px', justifyContent: 'center', alignItems: 'center' }} onClick={() => addToCartWithApi(item)}>
                            <IoMdCart style={{ fill: '#ff7043' }} />
                          </div>
                        )}
                      </div>
                    </div>

                  </div>
                </div>
              )
            })}
          </Slider>
        </div>

        {/* Mobile View Display 4 Products in One Div */}
        <div className="mobile-carousel">
          <Slider {...sliderSettings}>
            {mobileGroupedProducts.map((group, index) => (
              <div key={index} className="mobile-slide">
                <div className="mobile-grid">
                  {group.map((item, idx) => (
                    <div key={idx} className="mobile-item">
                      <Link
                        to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                        <img
                          src={decodeBase64Image(item.image)}
                          alt={item.name}
                          loading="lazy"
                          className="img-fluid"
                          style={{
                            objectFit: 'contain',
                            height: '120px',
                            width: '100%',
                          }}
                        />
                        <div className="mobile-shop-content">
                          <h3>{item.name}</h3>
                          <h3>₹ {item.price}</h3>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>}
    </div>
  );
};

export default TrendingItemsCarousel;

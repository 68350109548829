import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './MyOrdersTrack.css';
import vnn_1 from './vnn_1.jpg';
import vnn_2 from './vnn_2.jpg';
// import Review from './Review';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const MyOrdersTrack = ({ toggleComponents }) => {
    const location = useLocation();

    const { order, product } = location.state;

    const navigate = useNavigate();

    //   useEffect(() => {
    //     const handleResize = () => {
    //       if (window.innerWidth > 741) {
    //         navigate('/myorders');
    //       }
    //     };

    //     handleResize(); // Check on initial render

    //     window.addEventListener('resize', handleResize);

    //     return () => {
    //       window.removeEventListener('resize', handleResize);
    //     };
    //   }, [navigate]);

    return (
        <main>
            <br />
            <br />
            <div className="dlvr_adds">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="dlvr_cont">
                                <div className="order_cart_page">
                                    <div className="order_cart_body">
                                        <table>
                                            <thead>
                                                <tr style={{border: 'none'}}>
                                                    <td style={{ paddingTop: 20 }}>
                                                        <div className="orderId">
                                                            <span className="orderName">Order </span>{" "}
                                                            <span className="orderIdinfo">{order.order_id}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="cart-item" id="cartpro-1">
                                                    <td className="item-media" style={{paddingBottom: '10px'}}>
                                                        <div className="cart-image">
                                                            <a className="img">
                                                                <img src={`data:image/jpeg;base64,${atob(product.image_base64)}`} className="img-fluid item-img"
                                                                    alt="Product"
                                                                    loading="lazy"
                                                                    width="150"
                                                                    height="139" />
                                                            </a>
                                                            <div className="item-info">
                                                                <a href="" className="item-title">
                                                                    {product.product_name}
                                                                </a>
                                                                <div className="item-option">
                                                                    <span className="swatch-item">
                                                                        <span
                                                                            // style={{
                                                                            //     background: "#e1e1e1",
                                                                            //     padding: "1px 8px",
                                                                            //     borderRadius: 16,
                                                                            //     fontSize: 12
                                                                            // }}
                                                                        >
                                                                            <div className="qty">
                                                                                <span className="heading">Qty: </span>
                                                                                <span style={{ marginRight: '5px' }}>{product.quantity}</span>
                                                                            </div>
                                                                        </span>
                                                                        <div className="qty">
                                                                            <span
                                                                                
                                                                            >
                                                                                <span className="heading">Price: </span>${product.price_unit * product.quantity}
                                                                            </span>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                {/* <div className="text-center">
                                                                    <span
                                                                        style={{
                                                                            fontSize: 18,
                                                                            color: "black",
                                                                            top: 2,
                                                                            position: "relative"
                                                                        }}
                                                                    >
                                                                        Price:{" "}
                                                                    </span>
                                                                    <span
                                                                        className="item-pr"
                                                                        style={{
                                                                            fontSize: 18,
                                                                            color: "#000000d4",
                                                                            top: 2,
                                                                            fontWeight: 500,
                                                                            position: "relative"
                                                                        }}
                                                                    >
                                                                        {product.price_unit * product.quantity}
                                                                    </span>
                                                                </div> */}
                                                            </div>
                                                            <div style={{ width: "40%", marginRight: '50px' }}>
                                                                <div style={{ fontWeight: '600', marginTop: '10px' }}>
                                                                    {" "}
                                                                    Delivery Address
                                                                </div>
                                                                <div>{order.delivery_address.street}, {order.delivery_address.city}, {order.delivery_address.state}, {order.delivery_address.zip}, {order.delivery_address.country}</div>
                                                            </div>
                                                            <div className="cancel_form" style={{ marginTop: 30 }}>
                                                                {/* <div className="" style={{ marginTop: 10 }}>

                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div style={{display:'flex',justifyContent: 'flex-end'}}>
                                                            <div style={{width:'80%'}}>

                                                            </div>
                                                            <div className="" style={{ marginTop: 10,textAlign:'right' }}>
                                                                <Stack direction="row" spacing={1}>
                                                                    <Button variant='outlined' size='small'>Return</Button>
                                                                    <Button variant='outlined' size='small' color='error'>Cancel</Button>
                                                                </Stack>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    {/* <td>

												 <div> <span style="font-weight: 700; font-size: 18px;color: black;top: 2px; position: relative;">Total: </span>
													<span class="item-pr" style="font-size: 18px;color: #000000d4;  top: 2px;  font-weight: 500; position: relative;">$1952</span></div>
												</td> */}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <>
                            <div id="tracking" style={{ marginTop: '30px' }}>
                                <div className="tracking-list">
                                    <h4>Order Tracking</h4>
                                    <div className="or_tracking-item">
                                        <div className="tracking-icon status-intransit"></div>
                                        <div className="or_tracking-date">
                                            <img
                                                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/148866/cd-icon-picture.svg"
                                                className="img-responsive"
                                                alt="order-placed"
                                            />
                                        </div>
                                        <div className="or_tracking-content">
                                            Packed<div>09 Aug </div>
                                        </div>
                                    </div>
                                    {/* <div class="or_tracking-item">
												<div class="tracking-icon status-intransit">
												</div>
												<div class="or_tracking-date"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/148866/cd-icon-picture.svg" class="img-responsive" alt="order-placed"></div>
												<div class="or_tracking-content">Order Confirmed<span>09 Aug 2021, 10:30am</span></div>
											</div>
											<div class="or_tracking-item">
												<div class="tracking-icon status-intransit">
												</div>
												<div class="or_tracking-date"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/148866/cd-icon-picture.svg" class="img-responsive" alt="order-placed"></div>
												<div class="or_tracking-content">Packed the product<span>09 Aug 2021, 12:00pm</span></div>
											</div>
											<div class="or_tracking-item">
												<div class="tracking-icon status-intransit">
												</div>
												<div class="or_tracking-date"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/148866/cd-icon-picture.svg" class="img-responsive" alt="order-placed"></div>
												<div class="or_tracking-content">Arrived in the warehouse<span>10 Aug 2021, 02:00pm</span></div>
											</div> */}
                                    <div className="or_tracking-item">
                                        <div className="tracking-icon"></div>
                                        <div className="or_tracking-date">
                                            <img
                                                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/148866/cd-icon-picture.svg"
                                                className="img-responsive"
                                                alt="order-placed"
                                            />
                                        </div>
                                        <div className="or_tracking-content">
                                            At the transit center<div>10 Aug</div>
                                        </div>
                                    </div>
                                    <div className="or_tracking-item">
                                        <div className="tracking-icon"></div>
                                        <div className="or_tracking-date">
                                            <img
                                                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/148866/cd-icon-picture.svg"
                                                className="img-responsive"
                                                alt="order-placed"
                                            />
                                        </div>
                                        <div className="or_tracking-content">
                                            Being Delivered<div>10 Aug</div>
                                        </div>
                                    </div>
                                    <div className="or_tracking-item">
                                        <div className="tracking-icon"></div>
                                        <div className="or_tracking-date">
                                            <img
                                                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/148866/cd-icon-picture.svg"
                                                className="img-responsive"
                                                alt="order-placed"
                                            />
                                        </div>
                                        <div className="or_tracking-content">
                                            Delivered to you<div>10 Aug</div>
                                        </div>
                                    </div>
                                    {/* <div class="or_tracking-item-pending">
												<div class="tracking-icon status-intransit">
												</div>
												<div class="or_tracking-date"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/148866/cd-icon-picture.svg" class="img-responsive" alt="order-placed"></div>
												<div class="or_tracking-content">Being Delivered<div>12 Aug </div></div>
											</div>
											<div class="or_tracking-item-pending">
												<div class="tracking-icon status-intransit">
												</div>
												<div class="or_tracking-date"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/148866/cd-icon-picture.svg" class="img-responsive" alt="order-placed"></div>
												<div class="or_tracking-content">Delivere to you<div>12 Aug </div></div>
											</div> */}
                                </div>
                            </div>
                        </>

                    </div>
                </div>
            </div>


            {/* mobile view */}
            <div className="rs_order" style={{ marginTop: '70px' }}>
                <div className="orderId" style={{ marginLeft: '20px' }}>
                    <span className="orderName">Order </span>{" "}
                    <span className="orderIdinfo">#{order.order_id}</span>
                </div>
                <div className="orderTrack">
                    <span onClick={toggleComponents}>
                        {" "}
                        <i className="fas fa-angle-left" />
                    </span>
                </div>
                <div className="order_cart_page">
                    <div className="order_cart_body">
                        <table>
                            {/* <thead>
                  <tr>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Total</th>
                  </tr>
                </thead> */}
                            <tbody>
                                <tr className="cart-item" id="cartpro-1">
                                    <td className="item-media">
                                        <div className="cart-image">
                                            <a href="" className="img">
                                                <img src={`data:image/jpeg;base64,${atob(product.image_base64)}`} className="img-fluid item-img"
                                                    alt="Product"
                                                    loading="lazy"
                                                    width="150"
                                                    height="139" />
                                            </a>
                                            <div className="item-info">
                                                <a href="" className="item-title">
                                                    {product.product_name}
                                                </a>
                                                <div className="item-option">
                                                    {/* <span className="swatch-item pb-0 pt-3">
                                                        <span className="heading">By : </span>
                                                        Fire-Boltt
                                                    </span> */}
                                                    <span className="swatch-item">
                                                        {/* <span className="heading">Size:</span>
                                <span>S</span> */}
                                                        <span
                                                            className="qty"
                                                            style={{ padding: "-1px 20px" }}
                                                        >
                                                            <span className="heading">Qty : </span>
                                                            <span>{product.quantity}</span>
                                                        </span>
                                                        <span
                                                            className="item-pr"
                                                            style={{
                                                                fontWeight: 550,
                                                                fontSize: 18,
                                                                color: "black",
                                                                top: 2,
                                                                position: "relative",
                                                                paddingLeft: '30px',
                                                            }}
                                                        >
                                                            Price: <sup style={{ fontSize: '15px' }}>$</sup>{product.price_unit * product.quantity}
                                                        </span>
                                                    </span>
                                                </div>
                                                <ul
                                                    className="discounts list-unstyled"
                                                    role="list"
                                                    aria-label="Discount"
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    <div className="cart-item-totals" style={{ display: 'flex' }}>
                                        <div className="item-price" style={{ flex: 1 }}>
                                            <p className="mb-0" style={{ fontSize: '19px', fontWeight: 'bold', color: 'black' }}>Status</p>
                                            <span className="price-item price-item-regular" style={{ fontSize: '16px', fontWeight: 550 }}>
                                                In - Transit
                                            </span>
                                        </div>
                                        <div className="item-date" style={{ flex: 1 }}>
                                            <p className="mb-0" style={{ fontSize: '19px', fontWeight: 'bold', color: 'black' }}>Expected Delivery</p>
                                            <span className="price-item price-item-regular" style={{ fontSize: '16px', fontWeight: 550, color: 'gray' }}>
                                                6 May 2024
                                            </span>
                                        </div>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{ display: 'flex', width: '100%' }}>
                            {/* <Button style={{ flex: 1, marginRight: '5px' }} size='small' variant='contained'>Track</Button> */}
                            <Button style={{ flex: 1, marginRight: '5px' }} size='small' variant='outlined'>Return Order</Button>
                            <Button style={{ flex: 1 }} variant='outlined' color='error'>Cancel Order</Button>
                        </div>
                        {/* <Stack spacing={1} direction="row">
                            <Button size='small' variant='contained'>Track</Button>
                            <Button size='small' variant='contained'>Return</Button>
                            <Button color='error'>Cancel</Button>
                        </Stack> */}
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="ship-cart">
                        <div className="ship-cart-pr">
                            <h2 className="mb-4">Product tracking</h2>
                            <div id="tracking">
                                <div className="tracking-list">
                                    <div className="or_tracking-item">
                                        <div className="tracking-icon status-intransit"></div>
                                        <div className="or_tracking-date">
                                            <img
                                                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/148866/cd-icon-picture.svg"
                                                className="img-responsive"
                                                alt="order-placed"
                                            />
                                        </div>
                                        <div className="or_tracking-content">
                                            Initiated<span>09 Aug 2024</span>
                                        </div>
                                    </div>
                                    <div className="or_tracking-item">
                                        <div className="tracking-icon status-intransit"></div>
                                        <div className="or_tracking-date">
                                            <img
                                                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/148866/cd-icon-picture.svg"
                                                className="img-responsive"
                                                alt="order-placed"
                                            />
                                        </div>
                                        <div className="or_tracking-content">
                                            In transit <span>09 Aug 2024</span>
                                        </div>
                                    </div>
                                    <div className="or_tracking-item">
                                        <div className="tracking-icon status-intransit"></div>
                                        <div className="or_tracking-date">
                                            <img
                                                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/148866/cd-icon-picture.svg"
                                                className="img-responsive"
                                                alt="order-placed"
                                            />
                                        </div>
                                        <div className="or_tracking-content">
                                            Out for delivery<span>09 Aug 2024</span>
                                        </div>
                                    </div>
                                    <div className="or_tracking-item-pending">
                                        <div className="tracking-icon status-intransit"></div>
                                        <div className="or_tracking-date">
                                            <img
                                                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/148866/cd-icon-picture.svg"
                                                className="img-responsive"
                                                alt="order-placed"
                                            />
                                        </div>
                                        <div className="or_tracking-content">
                                            Delivered<span>12 Aug 2024</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="odr_bottom">
                    <button className="" style={{ fontWeight: 500 }}>
                        <i className="fas fa-times" /> CANCEL ORDER
                    </button>
                    <button className="" style={{ fontWeight: 500, paddingRight: '0px' }}>
                        <i className="fas fa-reply" /> RETURN ORDER
                    </button>
                </div> */}
            </div>

        </main>
    )
}

export default MyOrdersTrack